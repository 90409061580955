import React, { useState } from 'react';
import { Drawer, Dropdown } from 'antd';
import { DownOutlined, UpOutlined, MenuOutlined } from '@ant-design/icons';
import RegistrationButton from './RegistrationButton';
import Link from 'next/link';
import { NavLink } from './NavLink';
import type { MenuProps } from 'antd';
import { Menu } from 'antd';

type MenuItem = Required<MenuProps>['items'][number];

const openInNewTab = (url) => {
    window.open(url, '_blank', 'noreferrer');
};

const partnerItems = [
    {
        label: <a onClick={() => openInNewTab("https://edukasi.accelist.com/")}>Accelist Edukasi Indonesia</a>,
        key: '0',
    },
    {
        label: <a onClick={() => openInNewTab("https://ausrobotics.org/wit2023-the-8th-world-innovative-technology-challenge/")}>World Innovative Technology Challenge</a>,
        key: '1',
    },
    {
        label: 'Robotic Organizing Committee Indonesia',
        key: '2',
    },
    {
        label: <a onClick={() => openInNewTab("https://ukrida.ac.id/")}>Universitas Kristen Krida Wacana</a>,
        key: '3',
    },
    {
        label: <a onClick={() => openInNewTab("https://www.boschrexroth.com/en/id/")}>Bosch Rexroth Indonesia</a>,
        key: '4',
    },
];

const itemSidebar: MenuItem[] = [
    {
        key: 'sub1',
        label: 'Partner',
        children: partnerItems
    },
];

const items = [
    {
        label: "Home",
        href: "/",
        key: '0',
    },
    {
        label: "IIT Challenge Philosophy",
        href: "/iit-philosophy",
        key: '1',
    },
    {
        label: "IIT Challenge Program",
        href: "/iit-program",
        key: '3',
    },
]

export const Navbar: React.FC = () => {
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggleDrawer = () => {
        setDrawerVisible(!drawerVisible);
    };

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    return (
        <nav className="bg-white fixed w-full z-20 top-0 start-0 shadow-xl">
            <div className="max-w-screen-2xl mx-auto">
                <div className="flex justify-between items-center px-6 py-4">
                    <div className="flex items-center">
                        <Link href="/" className='hidden md:flex mr-4'>
                            <img alt="Logo" src="/images/iit-logo.webp" />
                        </Link>
                        <ul className="hidden md:flex lg:ml-6 space-x-8 rtl:space-x-reverse">
                            {items.map(item => (
                                <NavLink key={item.key} href={item.href}>
                                    {item.label}
                                </NavLink>
                            ))}
                            <Dropdown menu={{ items: partnerItems }} trigger={['click']} visible={dropdownOpen} onVisibleChange={toggleDropdown}>
                                <a onClick={(e) => e.preventDefault()} className="flex items-center text-gray-500 mx-4" >
                                    Partner
                                    {dropdownOpen ? (
                                        <UpOutlined style={{ fontSize: '14px', marginLeft: '5px', display: 'inline-flex', alignItems: 'center', lineHeight: '1', marginTop: '3px', marginRight: '12px'}} />
                                    ) : (
                                        <DownOutlined style={{ fontSize: '14px', marginLeft: '5px', display: 'inline-flex', alignItems: 'center', lineHeight: '1', marginTop: '3px', marginRight: '12px'  }} />
                                    )}
                                </a>
                            </Dropdown>
                        </ul>
                        <div className="flex items-center md:hidden ml-5">
                            <button onClick={toggleDrawer} className="text-gray-500">
                                <MenuOutlined style={{ fontSize: '24px' }} />
                            </button>
                        </div>
                    </div>
                    <Link href="/" className='md:hidden mr-5'>
                        <img alt="Logo" src="/images/iit-logo.webp" />
                    </Link>

                    <div className="items-center hidden md:flex">
                        <RegistrationButton />
                    </div>
                </div>
            </div>

            <Drawer
                placement="left"
                closable={true}
                onClose={toggleDrawer}
                visible={drawerVisible}
                className="md:hidden"
            >
                <div className="flex flex-col justify-between h-full">
                    <div className="py-4">
                        <Link href="/">
                            <img alt="Logo" src="/images/iit-logo.webp" className='px-2 mb-2' />
                        </Link>
                        <ul className="flex flex-col">
                            {items.map(item => (
                                <li key={item.key} className="py-2 px-2 mb-2" onClick={toggleDrawer}>
                                    <NavLink href={item.href}>
                                        {item.label}
                                    </NavLink>
                                </li>
                            ))}
                        </ul>
                        <Menu
                            mode="inline"
                            items={itemSidebar}
                            inlineIndent={4}
                            className='text-gray-500 mb-2'
                            onClick={toggleDrawer}
                        />
                    </div>
                    <div className="py-4">
                        <button onClick={() => openInNewTab("https://link.accelist.com/Registration")} type="button" className="text-white bg-defaultBlue hover:bg-dark focus:ring-darkBlue font-semibold rounded-lg px-4 py-2 text-center dark:bg-defaultBlue dark:hover:bg-darkBlue dark:focus:ring-defaultBlue w-full">Registration</button>
                    </div>
                </div>
            </Drawer>
        </nav>
    );
}
